import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useState } from 'react';

import {
  getBlogSlugValue,
  getOfferSlugValue,
  getUnitGuideSlugValue,
} from '@/components/utility';
import { islanFrench } from '@/helper/filterlogicInfo';
import Enkeys from '@/keys/enkeys';
import Frkeys from '@/keys/Frkeys';
import { getOverrideRcContactNumber, invendrLog } from '@/utils/invendr';

import StoreDataContext from './StoreDataContext';











const HeaderFooterContext = createContext(undefined);

export function HeaderFooterContextWrapper({ children }: any) {
  const [headerFooter, setHeaderFooter] = useState([]);
  const [isShowHeaderFooter, setIsShowHeaderFooter] = useState(true);
  const [isShowFooter, setIsShowFooter] = useState(true);
  const [isShowSelfHeader, setIsShowSelfHeader] = useState(true);

  const [viewUnitInfo, setViewUnitInfo] = useState({});
  const [facilityId, setFacilityId] = useState('');
  const [convenientInfo, setConvenientInfo] = useState({});

  const handleOverrideRcContactNumber = (data: any) => {
    const rcContactNumber = data[0]?.attributes?.RC_Contact_Number;
    const overrideRcContactNumber = getOverrideRcContactNumber();
    if (rcContactNumber && overrideRcContactNumber) {
      invendrLog(
        `Override RC contact number: ${rcContactNumber} => ${overrideRcContactNumber}`
      );
      // eslint-disable-next-line no-param-reassign
      data[0].attributes.RC_Contact_Number = overrideRcContactNumber;
    }
    return data;
  };

  function setHeaderFooterData(data: any) {
    handleOverrideRcContactNumber(data);
    setHeaderFooter(data);
  }

  const setShowHeaderFooter = (value: any) => {
    setIsShowHeaderFooter(value);
  };
  const setShowFooter = (value: any) => {
    setIsShowFooter(value);
  };
  const setShowSelfHeader = (value: any) => {
    setIsShowSelfHeader(value);
  };

  const setViewUnit = (value: any) => {
    setViewUnitInfo(value);
  };
  const setFacilityLCode = (value: any) => {
    setFacilityId(value);
  };
  const setConvenientLocations = (value: any) => {
    setConvenientInfo(value);
  };
  const { locale, pathname, query } = useRouter();
  const router = useRouter();
  const facilityPath = '/self-storage/[...location]';
  const store = useContext(StoreDataContext);

  useEffect(() => {
    if (
      router.asPath === '/self-storage/' ||
      Object.keys(router?.query)?.includes('lat') ||
      router.asPath === '/entreposage-libre-service/'
    ) {
      setIsShowHeaderFooter(false);
    } else {
      setIsShowHeaderFooter(true);
    }
    let enPath = '';
    const urlPath = router?.asPath.split('/');
    const { location } = query;

    if (pathname === '/offers/[offer]' && sessionStorage.getItem('offCords')) {
      urlPath[2] = getOfferSlugValue(locale);
    }
    if (pathname === '/blog/[slug]' && sessionStorage.getItem('blogCords')) {
      urlPath[2] = getBlogSlugValue(locale);
    }
    if (
      pathname === '/unit-guide/[slug]' &&
      sessionStorage.getItem('unitGuideCords')
    ) {
      urlPath[2] = getUnitGuideSlugValue(locale);
    }
    if (pathname === facilityPath && sessionStorage.getItem('facCords')) {
      let checkValue: any;
      const coords = JSON.parse(sessionStorage.getItem('facCords'));
      const getFacility = coords && coords.find((en: any) => en.lan === locale);
      if (locale === 'fr') {
        // remove fr for cms slug in frlang
        const getFacilitySlug = islanFrench({
          location: [getFacility.slugValue],
        });
        checkValue = getFacilitySlug && getFacilitySlug.location[0];
      } else {
        checkValue = getFacility && getFacility.slugValue;
      }
      // facility page route
      if (
        urlPath &&
        urlPath.length === 5 &&
        checkValue &&
        location &&
        location.length === 2
      ) {
        const compareSlugValue =
          coords &&
          coords.find(
            (en: any) =>
              en.slugValue === `${query.location[1]}-fr` ||
              en.slugValue === location[1]
          );
        if (compareSlugValue) urlPath[3] = checkValue;
        else urlPath[3] = location && location[1];
      }
      // single slug route for facility and city landing
      else if (
        urlPath &&
        urlPath.length === 4 &&
        checkValue &&
        location &&
        location.length === 1
      ) {
        const compareSlugValue =
          coords &&
          coords.find(
            (en: any) =>
              en.slugValue === `${query.location[0]}-fr` ||
              en.slugValue === location[0]
          );
        if (compareSlugValue) urlPath[2] = checkValue;
      }
    }
    if (
      (urlPath.indexOf('[slug]') >= 0 || urlPath.indexOf('[offer]') >= 0) &&
      pathname !== facilityPath
    ) {
      router.push(window.location.pathname, undefined, { locale });
    } else if (locale === 'en') {
      /* eslint-disable no-param-reassign */
      urlPath.forEach((el: any) => {
        if (el && el !== 'fr' && el !== 'en') {
          enPath += '/';
          el = Enkeys[el] === undefined ? el : Enkeys[el];
          enPath += el;
        }
      });

      router.push(`/en${enPath}`, undefined, { locale });
    } else if (locale === 'fr') {
      let frPath = '';
      /* eslint-disable no-param-reassign */
      urlPath.forEach((el: any) => {
        if (el && el !== 'en') {
          frPath += '/';
          el = Frkeys[el] === undefined ? el : Frkeys[el];
          frPath += el;
        }
      });
      router.replace(frPath, undefined, { locale, shallow: true });
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    store && store.setFilterData({});
  }, [router?.asPath, locale]);
  const sharedState = {
    headerFooter,
    isShowHeaderFooter,
    isShowFooter,
    isShowSelfHeader,
    setHeaderFooterData,
    setShowHeaderFooter,
    setShowFooter,
    setShowSelfHeader,
    setViewUnit,
    setFacilityLCode,
    setConvenientLocations,
    convenientInfo,
    viewUnitInfo,
    facilityId,
  };

  return (
    <HeaderFooterContext.Provider value={sharedState}>
      {children}
    </HeaderFooterContext.Provider>
  );
}

export function useAppContext() {
  return useContext(HeaderFooterContext);
}

export default HeaderFooterContext;
