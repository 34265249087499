/**
 * Set invendr data
 */
export const handleInvendr = () => {
    if (typeof window === "undefined" || typeof document === "undefined") {
        console.warn('handleInvendr failed, window is undefined or document is undefined');
        return;
    }
    defineDniInvendrScriptCallback();
    appendDniInvendrScript();
}

/**
 * RC override contact number for UI display
 */
export const getOverrideRcContactNumber = () => {
    if (isFindStorageFastReferral()) return FIND_STORAGE_FAST_OVERRIDE_CONTACT_NUMBER;
    return '';
}

/**
 * Invendr log for last 100 messages
 * @param message
 */
export const invendrLog = (message: string) => {
    if (typeof window === "undefined") {
        console.warn('invendrLog failed, window is undefined');
        return;
    }
    if (!window.invendrLog || window.invendrLog.length >= 100) window.invendrLog = [];
    if (message) window.invendrLog.push(`${new Date().toLocaleTimeString()} - ${message}`);
}

// --------------------------------------------------------------------------------------------------------------------

const DNI_INVENDR_SCRIPT_ID = 'dni.invendr';
const INVENDR_COOKIE_KEY = 'invendrData';
const FIND_STORAGE_FAST_OVERRIDE_CONTACT_NUMBER = '1-833-271-0978';

/**
 * Set dni.invendr script callback
 * 1) set window.invendr_data_cookie (retrieved from existing cookie)
 * 2) set window.invendr_data_next
 * 3) set window.invendr_data_dni_raw
 * 4) set window.invendr_data_dni
 * 5) set window.invendrData (main variable, used for form submissions)
 * 6) set window.invendrDataSources (utility variable for debugging with all the above)
 * 7) set new cookie
 */
const defineDniInvendrScriptCallback = () => {
    window.dni_invendr_callback = (data: any) => {
        set_window_invendr_data_cookie();
        set_window_invendr_data_next();
        set_window_invendr_data_dni_raw(data);
        set_window_invendr_data_dni();
        set_window_invendrData();
        set_window_invendr_data_sources();
        setInvendrCookie();
    };
}

const appendDniInvendrScript = () => {
    if (document.getElementById(DNI_INVENDR_SCRIPT_ID)) {
        invendrLog(`${DNI_INVENDR_SCRIPT_ID} script already loaded`);
    } else {
        const script = document?.createElement('script');
        script.src = "https://dev-invendr-dni.devv.ca/api/script/872865ba573a4be1.js?callback=dni_invendr_callback";
        script.id = DNI_INVENDR_SCRIPT_ID;
        script.async = true;
        document.body.appendChild(script);
        invendrLog(`${DNI_INVENDR_SCRIPT_ID} script appended`);
    }
}

/**
 * Retrieve invendr data from existing cookie
 */
const set_window_invendr_data_cookie = () => {
    const invendr_data_cookie_retrieve_string: any = getCookieJsonString(INVENDR_COOKIE_KEY);
    const invendr_data_cookie_retrieve = JSON.parse(invendr_data_cookie_retrieve_string);
    const invendr_data_cookie: InvendrData = {
        term: invendr_data_cookie_retrieve.term || '',
        content: invendr_data_cookie_retrieve.content || '',
        medium: invendr_data_cookie_retrieve.medium || '',
        campaign: invendr_data_cookie_retrieve.campaign || '',
        source: invendr_data_cookie_retrieve.source || '',
        mediaNumber: invendr_data_cookie_retrieve.mediaNumber || '',
        active_rule: invendr_data_cookie_retrieve.active_rule || '',
    }
    if (Object.values(invendr_data_cookie).every(value => value === '')) {
        invendrLog('Invendr cookie params not found');
    } else {
        invendrLog('Invendr cookie params found');
    }
    window.invendr_data_cookie = invendr_data_cookie;
}

/**
 * Set invendr data with new logic implemented in Next (URL params)
 */
const set_window_invendr_data_next = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const invendr_data_next: InvendrData = {
        term: urlParams.get('utm_term') || '',
        content: urlParams.get('utm_content') || '',
        medium: urlParams.get('utm_medium') || '',
        campaign: urlParams.get('utm_campaign') || '',
        source: urlParams.get('utm_source') || '',
        mediaNumber: '',
        active_rule: ''
    };
    if (Object.values(invendr_data_next).every(value => value === '')) {
        invendrLog('Invendr URL search params not found');
    } else {
        invendrLog('Invendr URL search params found');
    }
    window.invendr_data_next = invendr_data_next;
}

/**
 * Set raw data received from dni.invendr script
 * @param data
 */
const set_window_invendr_data_dni_raw = (data: any) => {
    window.invendr_data_dni_raw = data;
    invendrLog('Invendr DNI raw data loaded')
}

/**
 * Set invendr data received from dni script
 */
const set_window_invendr_data_dni = () => {
    const invendr_data_dni_raw = window.invendr_data_dni_raw;
    window.invendr_data_dni = {
        term: invendr_data_dni_raw.term || '',
        content: invendr_data_dni_raw.content || '',
        medium: invendr_data_dni_raw.medium || '',
        campaign: invendr_data_dni_raw.campaign || '',
        source: invendr_data_dni_raw.source || '',
        mediaNumber: invendr_data_dni_raw.mediaNumber || '',
        active_rule: invendr_data_dni_raw.active_rule || '',
    }
    if (Object.values(window.invendr_data_dni).every(value => value === '')) {
        invendrLog('Invendr DNI params are empty');
    } else {
        invendrLog('Invendr DNI params found');
    }
}

/**
 * Set main invendr variable that forms use for submission payload
 */
const set_window_invendrData = () => {
    const invendr_data_cookie = window.invendr_data_cookie;
    const invendr_data_dni = window.invendr_data_dni;
    const invendr_data_next = window.invendr_data_next;

    // Merge order: populate from cookie, if empty populate from next, if empty populate from dni
    window.invendrData = {
        term: invendr_data_cookie.term || invendr_data_next.term || invendr_data_dni.term,
        content: invendr_data_cookie.content || invendr_data_next.content || invendr_data_dni.content,
        medium: invendr_data_cookie.medium || invendr_data_next.medium || invendr_data_dni.medium,
        campaign: invendr_data_cookie.campaign || invendr_data_next.campaign || invendr_data_dni.campaign,
        source: invendr_data_cookie.source || invendr_data_next.source || invendr_data_dni.source,
        mediaNumber: invendr_data_cookie.mediaNumber || invendr_data_next.mediaNumber || invendr_data_dni.mediaNumber,
        active_rule: invendr_data_cookie.active_rule || invendr_data_next.active_rule || invendr_data_dni.active_rule,
    }
}

/**
 * Set utility variable with all invendr data sources before merge
 */
const set_window_invendr_data_sources = () => {
    const invendr_data_dni_raw = window.invendr_data_dni_raw;
    const invendr_data_cookie = window.invendr_data_cookie;
    const invendr_data_dni = window.invendr_data_dni;
    const invendr_data_next = window.invendr_data_next;
    const invendrData = window.invendrData;
    window.invendr_data_sources = {invendr_data_cookie, invendr_data_next, invendr_data_dni_raw, invendr_data_dni, invendrData};
}

/**
 * Set new cookie with current invendr data
 */
const setInvendrCookie = () => {
    const invendrData = window.invendrData;
    if (Object.values(invendrData).every(value => value === '')) {
        invendrLog('invendrData is empty, cookie will not be set');
    } else {
        invendrLog('invendrData found, cookie will be set');
        const invendrDataCookie = JSON.stringify(invendrData);
        setCookie(INVENDR_COOKIE_KEY, invendrDataCookie, 60);
    }
}

// --------------------------------------------------------------------------------------------------------------------

const getCookieJsonString = (key: string) => {
    const cookie = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return cookie ? cookie.pop() : "{}";
}

const setCookie = (key: string, value: string, days: number) => {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = key + "=" + (value || "") + expires + "; path=/";
}

const isFindStorageFastReferral = () => {
    if (typeof window === "undefined" || typeof document === "undefined") return false;
    return window.invendrData?.source === 'findstoragefast' || document.referrer.includes('findstoragefast');
}
